<!--
  作者：顾先生
-->
<template>
  <div class="bottom-container">
<!--    顶部内容-->
    <div class="top">
      <img src="../../assets/images/3.png">
      <span>中企信立信企业计划</span>
    </div>
<!--    中部内容-->
    <div class="middle">运营单位：备案信用服务机构|中雄世纪 <br><br> &nbsp;客服电话：400-0642-818&nbsp;公众号：中雄世纪中企信</div>
<!--    底部内容-->
    <div class="bottom">
      ©2016-2025中雄世纪征信有限公司版权所有  备案号：<a href="https://beian.miit.gov.cn" target="_blank">冀ICP备20017876号-9</a> &nbsp;<img src="../../assets/images/bto1.png"> <a href="https://www.gsxt.gov.cn/corp-query-homepage.html" target="_blank">工商登记信息</a>
      &nbsp;<img src="../../assets/images/wangan.png"> <a href="https://beian.mps.gov.cn" target="_blank">冀公网备案</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Bottom',
  components: {},
  methods: {},
  props: {},
  data () {
    return {

    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {}

}
</script>

<style scoped lang="less">
// 底部样式
.bottom-container{
  height: 245px;
  background-color: #ac2317;
  overflow: hidden;
  // 顶部内容
  .top{
    margin:40px auto 25px;
    width: 530px;
    height: 49px;
    img{
      width: 62px;
      height: 51px;
      vertical-align: top;
    }
    span{
      margin-left: 10px;
      font-family: FZDBSK;
      font-size: 50px;
      font-weight: normal;
      color: #ffffff;
    }
  }
  // 中部内容
  .middle{
    margin: 17px auto 0;
    width: 740px;
    height: 22px;
    text-align: center;
    font-size: 17px;
    font-weight: normal;
    letter-spacing: 1px;
    color: #ffffff;
  }
  // 底部内容
  .bottom{
    margin: 55px auto 0;
    width: 1087px;
    height: 25px;

    font-size: 20px;
    font-weight: normal;
    color: #ffffff;
    img{
      width: 22px;
      height: 22px;
      vertical-align: top;
    }
    a{
      color:#ffffff;
      transition:all .5s;
      &:hover{
        color: wheat;
        text-decoration:underline;
      }
    }
  }
}
</style>
